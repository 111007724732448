import React from 'react'
import './BigButton.scss'

export default function BigButton(props) {

    return (
        <div className="big-button-container"
            style={props.active?{backgroundColor: "#61A6F6"}:{}}
        >

            <label className="bigbuttontext">{props.children}</label>
            <input 
            name={props.name} 
            type="radio" 
            className="big-button-radio" 
            value={props.value}
            onChange={props.onChange}
            />

        </div>
        
    )
}
