import React, {useRef} from 'react';
import CTAButton from '../../Components/CTAButton/CTAButton';
import './CTAPanel.scss';
import {ScrollTrigger, Tween} from "react-gsap";
function CTAPanel(props) {
    const panel = useRef();
    return (
        <ScrollTrigger
        start={"top bottom"}
        end={"bottom top"}
        scrub={6}
        >

        <Tween
        to={
            {
                backgroundPosition: "center right 2000px"
            }
        }>
            <div className="cta-panel" ref={panel}>
                <div className="title">
                    <span className={"h2"}>{props.text.title}</span>
                    <span className={"h3"}>{props.text.subtitle}</span>
                </div>
                <CTAButton className="cta-button" />
            </div>
        </Tween>

        </ScrollTrigger>
    )
}

export default CTAPanel;
