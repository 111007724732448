import axios from "axios";
import {BackendData} from "./backend.data";

/** @module BackendService */

/**
 * sets the url of the backend
 * @type {string}
 */
let SERVER = "http://localhost:8080/api/"
if((document.location.origin!=="http://localhost:3000")) {
    SERVER = `${document.location.origin}/api/`;
}

//SERVER = "http://192.168.178.48:8080/api/"; //mobile test

/**
 * @returns {{Authorization: string}|{}} - Header to authenticate the admin
 */
function authHeader() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if(user?.token) {
        return {
            Authorization: 'Bearer ' + user.token
        };
    }else {
        return { };
    }
}

/**
 * @returns {{Authorization: string}|{}} - Header to authenticate the normal user
 */
function authHeaderEncode() {
    const user = JSON.parse(localStorage.getItem('user_encode'));
    if(user?.token) {
        return {
            Authorization: 'Bearer ' + user.token
        };
    }else {
        return { };
    }
}

/**
 * @returns {string} - Header to authenticate the admin
 */
function authHeaderString() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if(user?.token) {
        return 'Bearer ' + user.token;
    }else {
        return '';
    }
}

/**
 * The class with all methods to communicate to the backend
 *
 * **Tutorial: {@tutorial BackendService}**
 * @class
 */
class BackendService{

    /**
     * @property {Function} getServer
     * get the api url
     * @returns {string}
     */
    getServer(){
        return SERVER;
    }
    //------GET-----------
    /**
     * @property {Function} getAllEncodeMembers
     * Loads all encode member from the backend if not done already.
     * @returns {Promise<AxiosResponse<any>>} - Axios to fetch the response
     */
    getAllEncodeMembers(force){
        if(!BackendData.allEncodeMembersResponse || force ){
            return axios.get(
                SERVER + "member/all",
                {
                    headers: authHeaderEncode()
                }
            ).then(response => {
                BackendData.allEncodeMembersResponse = response
                return response
            });
        }else {
            return Promise.resolve(BackendData.allEncodeMembersResponse)
        }
    }

    /**
     * @property {Function} getEncodeMemberImage
     * Loads an image of an encode member from the backend if not already loaded.
     * @param id {string} - the id of the member
     * @returns {Promise<AxiosResponse<any>>} - Axios to fetch the response
     */
    getEncodeMemberImage(id, force){
        if(BackendData.allEncodeMemberImages.length===0 || force) {
            return axios.get(
                SERVER + "data/photo/" + id.toString(),
                {
                    headers: authHeaderEncode(),
                    responseType: 'blob'
                }
            ).then(response => {
                BackendData.allEncodeMemberImages.push({id: id, response: response})
                return response
            });
        }else {
            return Promise.resolve(BackendData.getImage(id))
        }
    }

    /**
     * @property {Function} getAllTopicElements
     * Loads all elements of all topics from the backend if not already loaded.
     * @param topic {string} - specifies for which topic (not implemented yet)
     * @returns {Promise<AxiosResponse<any>>} - Axios to fetch the response
     */
    getAllTopicElements(topic, force){
        if(!topic){ //wenn nicht für ein spezielles topic
            if(!BackendData.allTopicElementsResponse || force) {
                return axios.get(
                    SERVER + "topic/allElements",
                    {
                        headers: authHeaderEncode()
                    }
                ).then(response => {
                    BackendData.allTopicElementsResponse = response
                    return response
                })
            }else {
                return Promise.resolve(BackendData.allTopicElementsResponse)
            }
        }
    }

    /**
     * @property {Function} getAllTopics
     * Loads all topics from the backend if not already loaded.
     * @returns {Promise<AxiosResponse<any>>} - Axios to fetch the response
     */
    getAllTopics(force){
        if(!BackendData.allTopicsResponse || force) {
            return axios.get(
                SERVER + "topic",
                {
                    headers: authHeaderEncode()
                }
            ).then(response => {
                BackendData.allTopicsResponse = response
                return response
            })
        }else {
            return Promise.resolve(BackendData.allTopicsResponse)
        }
    }

    /**
     * @property {Function} getAllEncodeTestimonials
     * Loads all testimonials by language from the backend.
     * @param language {string} - de or en, sets the language
     * @returns {Promise<AxiosResponse<any>>} - Axios to fetch the response
     */
    getAllEncodeTestimonials(language){
        return axios.get(
            SERVER + "testimonial/all/"  + language,
            {
                headers: authHeaderEncode()
            }
        )
    }

    /**
     * @property {Function} getAllTestimonials
     * Loads all testimonials from the backend.
     * @returns {Promise<AxiosResponse<any>>} - Axios to fetch the response
     */
    getAllTestimonials(){
        return axios.get(
            SERVER + "testimonial/all",
            {
                headers: authHeaderEncode()
            }
        )
    }

    /**
     * @property {Function} getAllText
     * Loads the text for the website by language from the backend.
     * @param language
     * @returns {Promise<AxiosResponse<any>>} - Axios to fetch the response
     */
    getAllText(language){
        return axios.get(
            SERVER + "lang/" + language,
            {
                headers: authHeaderEncode()
            }
        );
    }
    //------GET-----------
    //------PUT-----------
    /**
     * @property {Function} putEncodeMember
     * Updates a encode member
     * @param member
     * @returns {Promise<AxiosResponse<any>>}
     */
    putEncodeMember(member){
        return axios.put(
            SERVER + "member/" + member.id,
            {
                vorname: member.vorname,
                nachname: member.nachname,
                bereich: member.bereich,
                istEhemalig: member.istEhemalig,
                likings: member.likings
            },
            {
                headers: authHeader()
            }
        )
    }
    putEncoderMemberPicture(picture, id){
        return axios.put(
            SERVER + "data/photo/" + id,
                picture,
            {
                        headers: authHeader()
            }
        )
    }
    putTopic(topic, id){
        return axios.put(
            SERVER + "topic/" + id,
            topic,
            {
                        headers: authHeader()
            }
        )
    }
    putTopicElement(topicElement, id) {
        return axios.put(
            SERVER + "topic/element/" + id,
            topicElement,
            {
                        headers: authHeader()
            }
        )
    }
    putTestimonial(testimonialRequest, id){
        return axios.put(
            SERVER + "testimonial/" + id,
            testimonialRequest,
            {
                        headers: authHeader()
            }
        )
    }
    //------PUT-----------
    //------POST-----------
    postEncodeMember(member){
        return axios.post(
            SERVER + "member/new",
            member,
            {
                        headers: authHeader()
            }
        )
    }
    postCheckAdmin(password){
        return axios.post(
            SERVER + "check",
            {
                name: 'admin',
                password: password
            }
        ).then(response => {
            console.log(response);
            if(response.data.token) {
                sessionStorage.setItem("user", JSON.stringify(response.data));
            }
        })
    }
    postCheckEncode(password){
        return axios.post(
            SERVER + "check/encode",
            {
                name: 'encode',
                password: password
            }
        ).then(response => {
            console.log(response);
            if(response.data.token) {
                localStorage.setItem("user_encode", JSON.stringify(response.data));
            }
        })
    }
    postChangePassword(newpassword){
        return axios.post(
            SERVER + "admin/change",
            newpassword,
            {
                headers: {
                  "Content-Type": "text/plain",
                    Authorization: authHeaderString()
                }
            }
        )
    }
    postTopicElement(newTopicElement){
        return axios.post(
            SERVER + "topic/element",
            {
                name: newTopicElement.de,
                nameEn: newTopicElement.en,
                topicName: newTopicElement.topicName
            },
            {
                headers: authHeader()
            }
        )
    }
    postTopic(newTopic){
        return axios.post(
            SERVER + "topic",
            {
                name: newTopic.de,
                nameEn: newTopic.en
            },
            {
                    headers: authHeader()
            }
        )
    }
    postTestimonial(testimonial){
        return axios.post(
            SERVER + "testimonial",
            testimonial,
            {
                    headers: authHeader()
            }
        )
    }
    //------POST-----------
    //------DELETE---------
    deleteMember(memberID){
        return axios.delete(
            SERVER + "member/" + memberID,
            {
                    headers: authHeader()
            }
        )
    }
    deleteTopic(topicID){
        return axios.delete(
            SERVER + "topic/" + topicID,
            {
                    headers: authHeader()
            }
        )
    }
    deleteTopicElement(topicElementID){
        return axios.delete(
            SERVER + "topic/element/" + topicElementID,
            {
                        headers: authHeader()
            }
        )
    }
    deleteTestimonial(id){
        return axios.delete(
            SERVER + "testimonial/" + id,
            {
                        headers: authHeader()
            }
        )
    }
    //------DELETE---------
}
export default new BackendService();