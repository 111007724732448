import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import './LogIn.scss'
import BackendService from "../../Services/backend.service"
import AdminPanel from "../AdminPanel/AdminPanel"
import getCurrentUser from '../../Services/User'
import { useHistory } from "react-router-dom";
import {AdminState} from "../AdminPanel/AdminContext/AdminState";

function LogIn(props) {
    const [eingeloggt, setEingeloggt] = useState(false);
    const [pw, setPw] = useState("")
    const navigate = useHistory()
    const login = (e) => {
        e.preventDefault();
        BackendService.postCheckAdmin(e.target[0].value).finally(
            ()=>{
                    if(getCurrentUser()){
                        setPw(e.target[0].value)
                        setEingeloggt(true);
                    }
            }
        )
    }
    useEffect(()=>{
        if(!localStorage.getItem('user_encode')){
            navigate.replace("/")
        }
        if(getCurrentUser()?.username==="admin") {
            setEingeloggt(true)
        }
    },[eingeloggt, navigate])
    return (
        <div>
            {
                !eingeloggt?
                <div className={"login"}>
                    <div>
                        <h1>Admin Login</h1>
                        <Form onSubmit={login}>
                        <Form.Group>
                        <Form.Control type="password"/>
                        <Form.Control type="submit" value="🔓" />
                        </Form.Group>
                        </Form>
                    </div>
                </div>
                    :
                    <AdminState>
                        <AdminPanel pw={pw}/>
                    </AdminState>
            }
        </div>
    );
}

export default LogIn;