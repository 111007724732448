import React, {useContext, useEffect, useState} from "react";
import AdminContext from "../AdminContext/AdminContext";
import {Formik} from "formik";
import * as yup from "yup";
import {Form} from "react-bootstrap";
import BackendService from "../../../Services/backend.service";

export const Testimonials = () => {
    const {
        members,
        testimonials,
        updateTestimonials,
        setAlert
    } = useContext(AdminContext)
    const [auswahlMember, setAuswahlMember] = useState(null)
    const [auswahlTestimonial, setAuswahlTestimonial] = useState(null)
    const [initialValues, setInitialValues] = useState({
        by: "",
        text: "",
        language: "DE"
    })
    useEffect(()=>{
        if(auswahlMember){
            setInitialValues(prevState => ({
                ...prevState,
                by: auswahlMember.vorname,
            }))
            setAuswahlTestimonial('')
        }
    }, [auswahlMember])
    useEffect(()=>{
        if(auswahlTestimonial){
            setInitialValues(prevState => ({
                ...prevState,
                text: auswahlTestimonial.text
            }))
        }
    }, [auswahlTestimonial])
    const validationSchema = yup.object().shape(
        {
            by: yup.string().required(),
            text: yup.string().required()
        }
    )
    const handleSubmit = (values, form) => {
        switch (values.submitType) {
            case "add":
                BackendService.postTestimonial({
                    text: values.text,
                    by: auswahlMember.id,
                    language: values.language
                }).finally(()=>{
                    setAlert({msg: "Testimonial erfolgreich erstellt!", show: true, variant: "info"})
                    updateTestimonials()
                }).catch(error => {
                    setAlert({msg: error.toString(), show: true, variant: "danger"})
                })
                break
            case "change":
                BackendService.putTestimonial({
                    text: values.text,
                    by: auswahlMember.id,
                    language: values.language
                }, auswahlTestimonial.id
                ).finally(()=>{
                    setAlert({msg: "Testimonial erfolgreich geupdated!", show: true, variant: "info"})
                    updateTestimonials()
                }).catch(error => {
                    setAlert({msg: error.toString(), show: true, variant: "danger"})
                })
                break
            case "delete":
                BackendService.deleteTestimonial(
                    auswahlTestimonial.id
                ).finally(()=>{
                    setAlert({msg: "Testimonial erfolgreich gelöscht!", show: true, variant: "info"})
                    updateTestimonials()
                }).catch(error => {
                    setAlert({msg: error.toString(), show: true, variant: "danger"})
                })
                break
            default:
                break
        }
        form.resetForm()
    }
    const FormikWrapper = ({InnerForm})=>(
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}>
            {
                (props) => (
                    <InnerForm {...props}/>
                )
            }
        </Formik>
    )
    const FormikFormTestimonial = (props) => (
        <Form noValidate onSubmit={props.handleSubmit}>
            <FormBy {...props} />
            <FormTestimonialText {...props} />
            <FormLanguage {...props} />
            <Form.Control type="submit" onClick={()=>props.setFieldValue("submitType", "add")} role={"button"} value="hinzufügen"/>
            <Form.Control disabled={!auswahlTestimonial} type="submit" onClick={()=>props.setFieldValue("submitType", "change")} role={"button"} value="ändern"/>
            <Form.Control disabled={!auswahlTestimonial} type="submit" onClick={()=>props.setFieldValue("submitType", "delete")} role={"button"} style={{backgroundColor: "rgba(255,0,0, 0.5)"}} value="löschen"/>
        </Form>
    )

    const FormBy = ({handleChange, handleBlur, errors, values, touched}) => (
        <Form.Group>
            <Form.Control name="by" onBlur={handleBlur} onChange={handleChange} value={values.by} type="text" placeholder="Von..." isInvalid={!!errors.by && touched.by} readOnly={true}/>
        </Form.Group>
    )

    const FormTestimonialText = ({handleChange, handleBlur, errors, values, touched}) => (
        <Form.Group>
            <Form.Control name="text" onBlur={handleBlur} onChange={handleChange} value={values.text} type="text" placeholder="Text..." isInvalid={!!errors.text && touched.text}/>
            {!!errors.text && touched.text?<span style={{color: "red"}}>{errors.text}</span>:null}
        </Form.Group>
    )

    const FormLanguage = ({handleChange, values}) => (
        <Form.Group>
            <Form.Control as="select" name={"language"} value={values.language} onChange={handleChange}>
                <option value={"DE"}>DE</option>
                <option value={"EN"}>EN</option>
            </Form.Control>
        </Form.Group>
    )

    return (
        <section className={"Testimonials"}>
            <h2>Testimonials</h2>
            <ul className={"auswahl"}>
                {members?.map((member, index)=>
                    <li className={"member-auswahl"} onClick={_=>setAuswahlMember(member)} key={index}>
                        {member.vorname} {member.nachname}
                    </li>
                )}
            </ul>
            <ul className={"auswahl"}>
                {testimonials?.map((testimonial, index)=> {
                        if(testimonial?.by.id === auswahlMember?.id){
                            return <li className={"testimonial-auswahl"} onClick={_ => setAuswahlTestimonial(testimonial)}
                                       key={index}>
                                {testimonial.text}
                            </li>
                        }else {
                            return null
                        }
                    }
                )}
            </ul>
            <FormikWrapper InnerForm={FormikFormTestimonial}/>
        </section>
    )
}