import {useEffect, useState} from 'react';

function UseIsOnScreen(ref) {
    const [isInScreen, setIsInScreen] = useState(false);
    const check = new IntersectionObserver((([element]) => setIsInScreen(element.isIntersecting)));
    useEffect(()=>{
        check.observe(ref.current)
        return ()=>{
            check.disconnect()
        }
    })
    return isInScreen;
}

export default UseIsOnScreen;