import React, {useContext} from 'react'
import { Button } from 'react-bootstrap'
import './CTAButton.scss'
import TextContext from "../../Text/TextContext";

export default function CTAButton() {
    const {text} = useContext(TextContext)
    return (
        <Button className="cta-button"
            onClick={_=>window.open("https://adorsys.atlassian.net/wiki/spaces/DW/pages/12747514/Bewerberliste+encode")}
        >
            {text.ctaPanel.button}
        </Button>
    )
}
