import React, {useState} from 'react';
import TextContext from "./TextContext";
import BackendService from "../Services/backend.service";

/** @module TextState */

/**
 * Handles the state for the TextContext.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function TextState(props) {
    let initialState = {
        text: null
    }

    const [state, setState] = useState(initialState);

    const getText = async (language) => {
        try {
            await BackendService.getAllText(language).then(response => {
                let { data } = response;
                if(data) {
                    setState({
                        text: data
                    });
                }
            })
        } catch (error){
            console.log(error);
        }
    }

    return (
        <TextContext.Provider
            value={
                {
                    text: state.text,
                    getText: getText
                }
            }
        >
            {props.children}
        </TextContext.Provider>
    );
}

export default TextState;