import React from 'react'
import { Button } from 'react-bootstrap'
import './NormalButton.scss'

export default function NormalButton(props) {
    return (
        <Button className="normal-button" onClick={props.onClick}>
            {props.children}
        </Button>
    )
}
