import React, {useContext, useEffect, useState} from "react"
import BackendService from "../../../Services/backend.service"
import * as yup from "yup"
import {FieldArray, Formik} from "formik"
import {Form, FormCheck, FormGroup} from "react-bootstrap"
import AdminContext from "../AdminContext/AdminContext"

export function Members(props){
    const {
        members,
        updateMembers,
        topicElements,
        setAlert
    } = useContext(AdminContext)
    const [auswahl, setAuswahl] = useState(null)
    const [initialValues, setInitialValues] = useState({
        vorname: '',
        nachname: '',
        bereich: '',
        likingNew: [],
        bildNew: "",
        ehemalig: false,
        submitType: ""
    })

    useEffect(()=>{
        if(auswahl){
            setInitialValues({
                vorname: auswahl.vorname,
                nachname: auswahl.nachname,
                bereich: auswahl.bereich,
                likingNew: auswahl.likings,
                bildNew: "",
                ehemalig: auswahl.istEhemalig,
                submitType: ""
            })
        }else{
            setInitialValues({
                vorname: '',
                nachname: '',
                bereich: '',
                likingNew: [],
                bildNew: "",
                ehemalig: false,
                submitType: ""
            })
        }
    },[auswahl])

    const handleSubmit = (values, form)=>{
        switch (values.submitType) {
            case "add":
                BackendService.postEncodeMember(
                    getNewMemberResponseBody(values)
                )
                .then(({data}) => {
                    uploadImage(data.id, values.bildNew)
                })
                .finally(() => {
                    setAlert({msg: 'Member erfolgreich hinzugefügt!', show: true, variant: "info"})
                    updateMembers()
                }).catch(error=>{
                setAlert({msg: error.toString(), show: true, variant: "danger"})
                })
                resetForm(form)
                break
            case "change":
                BackendService.putEncodeMember(({...getNewMemberResponseBody(values), id: auswahl.id})).then(
                    ({data}) => {
                        uploadImage(data.id, values.bildNew)
                    }
                )
                .finally(()=>{
                    setAlert({msg: 'Member erfolgreich upgedated!', show: true, variant: "info"})
                    updateMembers()
                    setAuswahl(null)
                }).catch(error=>{
                    setAlert({msg: error.toString(), show: true, variant: "danger"})
                })
                break
            case "delete":
                BackendService.deleteMember(auswahl.id).finally(()=> {
                    setAlert({msg: 'Member erfolgreich gelöscht!', show: true, variant: "info"})
                    updateMembers()
                }).catch(error=>{
                    setAlert({msg: error.toString(), show: true, variant: "danger"})
                })
                resetForm(form)
                break
            default:
                break
        }
    }

    const uploadImage = (memberId, image) => {
        if(!image || image==="") {
            return
        }
        let formData = new FormData()
        formData.append("image", image)
        BackendService.putEncoderMemberPicture(formData, memberId).finally()
    }

    const resetForm = (form) => {
        form.resetForm()
    }

    const getNewMemberResponseBody = (values)=> ({
        vorname: values.vorname,
        nachname: values.nachname,
        bereich: values.bereich,
        istEhemalig: values.ehemalig,
        likings: values.likingNew?values.likingNew:[]
    })

    const validationSchema = yup.object().shape(
        {
            vorname: yup.string().required(),
            nachname: yup.string().required()
        }
    )

    const FormikWrapper = ({InnerForm})=>(
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}>
            {
                (props) => (
                    <InnerForm {...props}/>
                )
            }
        </Formik>
    )

    const FormikFormMember = (props) => (
        <Form noValidate onSubmit={props.handleSubmit}>
            <FormVorname {...props}/>
            <FormNachname {...props}/>
            <FormBereich {...props}/>
            <FormLikings {...props}/>
            <FormBild {...props}/>
            <FormEhemalig {...props}/>
            <Form.Control type="submit" onClick={()=>props.setFieldValue("submitType", "add")} role={"button"} value="hinzufügen"/>
            <Form.Control type="submit" onClick={()=>props.setFieldValue("submitType", "change")} role={"button"} value="ändern"/>
            <Form.Control type="submit" onClick={()=>props.setFieldValue("submitType", "delete")} role={"button"} style={{backgroundColor: "rgba(255,0,0, 0.5)"}} value="löschen"/>
        </Form>
    )

    const FormVorname = ({handleChange, handleBlur, values, touched, errors}) => (
        <Form.Group>
            <Form.Control
                type="text"
                name={"vorname"}
                placeholder="Vorname"
                value={values.vorname}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.vorname && touched.vorname}
            />
            {(!!errors.vorname && touched.vorname)?<span style={{color: "red"}}>{errors.vorname}</span>:null}
        </Form.Group>
    )

    const FormNachname = ({handleChange, handleBlur, values, touched, errors}) => (
        <Form.Group>
            <Form.Control
                type="text"
                name={"nachname"}
                placeholder="Nachname"
                value={values.nachname}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.nachname && touched.nachname}
            />
            {(!!errors.nachname && touched.nachname)?<span style={{color: "red"}}>{errors.nachname}</span>:null}
        </Form.Group>
    )

    const FormBereich = ({handleChange, values})=>(
        <Form.Group>
            <Form.Control value={values.bereich} name={"bereich"} onChange={handleChange} type="text" placeholder="Bereich"/>
        </Form.Group>
    )

    const FormLikings = ({handleChange, values}) => (
        <Form.Group>
            <FieldArray name={"likings"}>
                <div key={"default-checkbox"}>
                    {
                        topicElements?
                            topicElements.map((element, index)=> {
                                return (
                                    <div key={index}>
                                        <Form.Check
                                            type={"checkbox"}
                                            label={element.name}
                                            value={element.name}
                                            checked={values.likingNew.includes(element.name)}
                                            onChange={handleChange}
                                            name={"likingNew"}
                                        />
                                    </div>
                                )
                            })
                            :""
                    }
                </div>
            </FieldArray>
        </Form.Group>
    )

    const FormBild = ({setFieldValue, values})=>(
        <Form.Group>
            <Form.Label>Bild</Form.Label>
            <Form.Control
                type={"file"}
                name={"bildNew"}
                id={"new-image-file"}
                onChange = {(event) => {
                    console.log("...")
                    setFieldValue("bildNew", event.currentTarget.files[0])
                }}
            />
        </Form.Group>
    )

    const FormEhemalig = ({handleChange})=>(
        <FormGroup>
            <FormCheck name={"ehemalig"} onChange={handleChange} type={"checkbox"} label={"ist Ehemalig?"}/>
        </FormGroup>
    )

    return (
        <section className="Members">
            <h2>Encode Member</h2>
            <ul className={"auswahl"}>
                {members?.map((member, index)=>
                    <li className={"member-auswahl"} onClick={_=>setAuswahl(member)} key={index}>
                        {member.vorname} {member.nachname}
                    </li>
                )}
            </ul>
            <FormikWrapper InnerForm={FormikFormMember} />
        </section>
    )
}
