import React from 'react';
import './Footer.scss';
import {ReactComponent as Logo} from '../../svgs/logo-white.svg'
import CTAButton from '../../Components/CTAButton/CTAButton';
import PeaceIcon from './imgs/peace.png'
import {ScrollTrigger, Tween} from "react-gsap";


export function Footer(props) {

    return (
        <ScrollTrigger
            start={"top bottom"}
            end={"bottom center"}
            scrub={6}
        >
            <Tween
                to={
                    {
                        backgroundPosition: "center right 1000px"
                    }
                }>
                <footer id="footer-panel">
                    <img height="80px" src={PeaceIcon} alt="peace emoji" className="peace-icon"></img>
                    <Logo className="logo"/>
                    <h2>{props.text.title}</h2>
                    <CTAButton />
                </footer>
            </Tween>
        </ScrollTrigger>
    )
}

export default Footer;
