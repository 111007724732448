import './App.scss';
import React, { Component } from 'react'
import WelcomeScreen from './Screens/WelcomeScreen/WelcomeScreen';
import DiscoverScreen from './Screens/DiscoverScreen/DiscoverScreen';
import AboutYouScreen from './Screens/AboutYouScreen/AboutYouScreen';
import OurValuesScreen from './Screens/OurValuesScreen/OurValuesScreen';
import SimilatirysScreen from './Screens/SimilatirysScreen/SimilatirysScreen';
import CustomerScreen from './Screens/CustomerScreen/CustomerScreen';
import TestimonialScreen from './Screens/TestimonialScreen/TestimonialScreen';
import Header from './Panels/Header/Header';
import CTAPanel from './Panels/CTAPanel/CTAPanel';
import Footer from './Panels/Footer/Footer';
import TextContext from "./Text/TextContext";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import LogIn from "./AdminPanel/LogIn/LogIn";
import EncodeLogin from "./Components/EncodeLogin/EncodeLogin";

/** @module App */

/**
 * Main Component in which all so called "Screens" are loaded if the user has validated him-/herself.
 */
export default class App extends Component {
    /**
     * Sets the ContextType to the context "{@link module:TextContext}"
     * @type {React.Context}
     */
    static contextType = TextContext;

    /**
     * The constructor of the App. It sets the state "contextLoaded" to false as default.
     * "contextLoaded" is true if the text of the website is loaded from the backend.
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            contextLoaded: false
        }
    }

    /**
     * This Reacts Lifecycle-method tries to get the text of the website from the backend through the context api of React.
     * If the text couldn't load but a jwt-token is found it deletes that token because it is probably invalid and reload the page,
     * so that the login form is seen.
     */
    componentDidMount() {
        this.context.getText("de").then(_=> {
            if (this.context.text) {
                this.setState({
                    contextLoaded: true,
                })
            }else{
                //invalid
                if(localStorage.getItem('user_encode')){
                    localStorage.removeItem('user_encode')
                    window.history.go()
                }
            }
        })
    }

    /**
     * Renders Reacts Router.
     * For the path /admin the component {@link LogIn} is loaded, so that the admin can login.
     * On the default path / the component {@link PageConstruction} is loaded if the text of the website is loaded from the Backend.
     * Otherwise it loads the {@link EncodeLogin} component if the user has not authenticated him-/herself.
     * @returns {JSX.Element}
     */
    render() {
    return (
          <BrowserRouter>
              <Switch>
                  <Route exact path="/admin">
                      <LogIn />
                  </Route>
                  <Route path="/">
                      {this.state.contextLoaded?<PageConstruction/>:
                          <div id={"loading"}><p><small>Loading...</small></p>
                              {localStorage.getItem('user_encode')?null:<EncodeLogin/>}
                      </div>}
                  </Route>
              </Switch>
          </BrowserRouter>
    )
  }
}
/**
 * The PageConstruction loads all Screens.
 * It is packed into a Consumer of the {@link module:TextContext}, so that it can pass on the website text from the backend.
 * @returns {JSX.Element}
 * @constructor
 */
function PageConstruction(props) {

  return <TextContext.Consumer>{ ({text}) => (
    <>
        <Header />
        <WelcomeScreen text={text.welcomeScreen}/>
        <DiscoverScreen text={text.discoverScreen}/>
        <AboutYouScreen text={text.aboutYouScreen}/>
        <OurValuesScreen text={text.ourValuesScreen}/>
        <SimilatirysScreen text={text.similatirysScreen}/>
        <CTAPanel text={text.ctaPanel}/>
        <CustomerScreen text={text.customerScreen}/>
        <TestimonialScreen text={text.testimonialScreen}/>
        <Footer text={text.footer}/>

    </>
  )}
  </TextContext.Consumer>
}