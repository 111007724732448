import React, {Component, useRef} from 'react';
import './DiscoverScreen.scss';

import {ReactComponent as DuBlob} from './svgs/duBlob.svg';
import {ReactComponent as EncodeBlob} from './svgs/encodeBlob.svg';
import useIsOnScreen from '../../Helper/useIsOnScreen'

export class DiscoverScreen extends Component {
    render() {
        return (
            <section className="screen" id="discover-screen">
                <div className="head">
                    <h2 className={"h1"}>{this.props.text.title}</h2>
                    <span className={"h2"}>{this.props.text.subtitle}</span>
                </div>
                <DuUndEncode text={this.props.text}/>
            </section>
        )
    }
}

function DuUndEncode(props) {
    const element = useRef();
    const inScreen = useIsOnScreen(element);

    return (
        <div className="du-und-encode" ref={element}>
            {
                inScreen?
                    <>
                        <DuBlob className="du-blob animate-du"/>
                        <EncodeBlob className="encode-blob animate-encode"/>
                        <div className="text animate-text">

                        <div className="und-text">
                        <span className="du-text h1">{props.text.you}</span>
                            <span className="und-text h1">&</span>
                        <span className="encode-text h1">encode</span>
                        </div>
                        </div>
                    </>
                    :
                    <>
                        <DuBlob className="du-blob transp"/>
                        <EncodeBlob className="encode-blob transp"/>
                        <div className="text transp">

                            <div className="und-text">
                                <h1 className="du-text">{props.text.you}</h1>
                                <h1 className="und-text">&</h1>
                                <h1 className="encode-text">encode</h1>
                            </div>
                        </div>
                    </>
            }
        </div>
    );
}

export default DiscoverScreen;
