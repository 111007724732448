class _BackendData {
    allEncodeMemberImages = []
    allEncodeMembersResponse = undefined
    allTopicElementsResponse = undefined
    allTopicsResponse = undefined
    getImage = (id) => {
        let _image = undefined
        for(let image of this.allEncodeMemberImages){
            if(image.id === id) {
                _image = image.response
                break
            }
        }
        return _image
    }
}
export const BackendData = new _BackendData()