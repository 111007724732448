import React, {/*useRef*/} from 'react';
import './WelcomeScreen.scss';
import {ReactComponent as Drawing} from './svgs/drawing.svg';
import {ReactComponent as Glowing} from './svgs/gradient.svg';
import {ReactComponent as Arrow} from './svgs/arrow/arrow.svg';
import {Tween} from "react-gsap";
import {ScrollTrigger} from "react-gsap";
/*
import useDimensions from "react-cool-dimensions";
import { useEffect } from 'react';
*/

function WelcomeScreen(props){

    return (
        <ScrollTrigger
            start={"top top"}
            end={"100% top"}
            scrub={0.5}
            pin={true}
            pinSpacing={false}
        >
            <Tween
                to={
                    {
                        y: "-10%",
                        opacity: "0.0"
                    }
                }
            >
                <section className="screen" id="welcome-screen">
                    {/*<MyCanvas/>*/}

                        <div className="welcome-drawing">
                            <h1>{props.text.title}</h1>
                            <Glowing className="drawing"/>
                            <Drawing className="drawing"/>
                        </div>
                        <div className="welcome-arrow">
                            <div className="background" />
                            <Arrow className="drawing" />
                        </div>
                </section>
            </Tween>
        </ScrollTrigger>

    )
}

/*
function MyCanvas(props){

    const canvas = useRef(null);
    const overlay = useRef(null);
    let context;
    let rect;
    let pointBuffer = [
        {x: 0, y:0},
        {x: 0, y:0},
        {x: 0, y:0},
        {x: 0, y:0},
        {x: 0, y:0},
    ];
    let bufferPos = 0;
    let reset = false;
    let mousePosition;

    useEffect(() => {
        initCanvas();
        return () => {
            canvas.current.removeEventListener("mousemove", ()=>{});
        }
    });

    const {observe, unobserve, width, height} = useDimensions({
        onResize: ({observe, unobserve, width, height}) => {
            unobserve();
            observe();
        }
    });


    const initCanvas = () => {
        console.log(canvas)
        context = canvas.current.getContext("2d");
        drawOverlayer(overlay.current.getContext("2d"));
        rect = canvas.current.getBoundingClientRect();
        canvas.current.removeEventListener("mousemove", ()=>{});
        canvas.current.addEventListener("mousemove", (e)=>drawLinesOnCanvas(e));
        canvas.current.addEventListener("mouseleave", (e)=>{resetPointBuffer();canvas.current.removeEventListener("mousemove", ()=>{})});
    }

    const drawOverlayer = (context) => {
        let gradient = context.createLinearGradient(width/2,0,width/2,height);
        gradient.addColorStop(0, '#FCFAF9');
        gradient.addColorStop(.3, '#FCFAF900');
        gradient.addColorStop(.5, '#FCFAF900');
        gradient.addColorStop(.7, '#FCFAF900');
        gradient.addColorStop(1, '#FCFAF9');
        context.fillStyle = gradient;
        context.fillRect(0,0,width,height);
    }

    const drawLinesOnCanvas = (e) => {
        mousePosition = mousePositionInCanvas(rect,e);
        if(!reset) {
            pointBuffer[bufferPos] = mousePosition;
            bufferPos++;
            if (bufferPos > pointBuffer.length - 1) reset = true;
            bufferPos = bufferPos % pointBuffer.length;
            if (pointBuffer[0].x != 0 && pointBuffer[pointBuffer.length - 1].x != 0 ) {
                context.beginPath();
                context.moveTo(pointBuffer[0].x, pointBuffer[0].y);
                context.strokeStyle = "#61A6F6";
                context.lineWidth = 4;
                context.lineCap = "round";
                context.lineJoin = "round";
                context.bezierCurveTo(
                    pointBuffer[Math.floor(pointBuffer.length / 4)].x,
                    pointBuffer[Math.floor(pointBuffer.length / 4)].y,
                    pointBuffer[Math.floor(pointBuffer.length / 1.5)].x,
                    pointBuffer[Math.floor(pointBuffer.length / 1.5)].y,
                    pointBuffer[pointBuffer.length - 1].x ,
                    pointBuffer[pointBuffer.length - 1].y );
                context.stroke();
            }
            if (reset) {
                let lastPoint = pointBuffer[pointBuffer.length - 1];
                resetPointBuffer();
                pointBuffer[0] = lastPoint;
                bufferPos = 1;
                reset = false;
            }
        }
    }

    const resetPointBuffer = ()=>{
        for(let i = 0; i < pointBuffer.length; i++)
        pointBuffer[i] = {x: 0, y: 0};
    }

    const mousePositionInCanvas = (rect, event)=>{
        return {
            x: event.clientX - rect.left - (event.clientX-event.pageX),
            y: event.clientY - rect.top - (event.clientY-event.pageY)
        }
    }

    return (
        <div ref={observe} className="my-canvas">
            <canvas width={width} height={height} className="overlay-canvas" ref={overlay}></canvas>
            <canvas width={width} height={height} className="drawing-lines-canvas" ref={canvas}></canvas>
        </div>
    )
}

 */
export default WelcomeScreen;
