import {useEffect, useState} from "react";
import BackendService from "../../../Services/backend.service";
let block = false

export const useTestimonials = () => {
    const [testimonials, setTestimonials] = useState([]);
    useEffect(()=>{
        if(testimonials.length===0 && !block){
            update()
            block = true
        }
    })

    const update = () => {
        BackendService.getAllTestimonials().then(
            ({data})=>{
                setTestimonials(data)
                console.log("test")
            }).catch((error)=>{
        })
    }

    return [testimonials, update]
}