import React, { Component } from 'react';
import './Header.scss';
import Navigation from './Navigation/Navigation';
export class Header extends Component {
    render() {
        return (
            <header id="header-panel">
                <Navigation />
            </header>
        )
    }
}

export default Header;
