import {useEffect, useState} from "react";
import BackendService from "../../../Services/backend.service";
let block = false

export const useMembers = () => {
    const [members, setMembers] = useState([])
    useEffect(()=>{
        if(members.length===0 && !block) {
            update()
            block = true
        }
    })
    const update = () => {
        BackendService.getAllEncodeMembers("force").then(
            ({data}) => {
                console.log("test")
                setMembers(data)
            }
        ).catch((error)=>{
        })
    }
    return [members, update]
}