import {createContext} from "react";

/** @module TextContext */

/**
 * Creates the TextContext for the website
 * @type {React.Context<unknown>}
 */
const TextContext = createContext();

export default TextContext;