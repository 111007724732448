import { Nav, Navbar, NavbarBrand, NavLink } from 'react-bootstrap';
import {ReactComponent as Logo} from '../../../svgs/logo.svg';
import './Navigation.scss';
import {useContext, useState} from "react";
import TextContext from "../../../Text/TextContext";

function Navigation(props){


    return (
            <Navbar expand="md" id="header-navigation">
                <div className={"logo-container"}>
                <NavbarBrand href="#welcome-screen">
                    <Logo className="logo" />
                </NavbarBrand>
                </div>
                <div className="navcollapse">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav">
                        <span className={"navbar-toolbar"}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </Navbar.Toggle>
                    <Navbar.Collapse className="auswahl">
                        <Nav className="me-auto">
                            <BigNavigation />
                            <div className={"space"}></div>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        );
}

function BigNavigation(props){
    const context = useContext(TextContext);
    const [language, setLanguage] = useState("de");
    return (
            <>
                    <NavLink className={"navigation"} href="#our-values-screen">{context.text.header.nav[0]}</NavLink>
                    <NavLink className={"navigation"} href="#similatiry-screen">{context.text.header.nav[1]}</NavLink>
                    <NavLink className={"navigation"} href="#customer-screen">{context.text.header.nav[2]}</NavLink>
                    <NavLink className={"navigation change-language"} href="#" onClick={_=>
                        {
                            let newLanguage = language==="de"?"en":"de";
                            context.getText(newLanguage).then(
                                _=>{
                                    setLanguage(newLanguage)
                                }
                            )
                        }
                    }>
                        <span className="stroke"></span>
                        {
                            language==="en"?
                            (<p><strong>EN</strong>/DE</p>)
                                :
                            (<p>EN/<strong>DE</strong></p>)
                        }
                    </NavLink>
            </>
    )
}

export default Navigation;
