import React, { Component } from 'react';
import './OurValuesScreen.scss';
import {ReactComponent as HeartIcon} from './svgs/heart.svg'
export class OurValuesScreen extends Component {
    render() {
        return (
            <section className="screen" id="our-values-screen">
                <div className={"our-values-screen-header"}>
                    <HeartIcon className="icon" />
                    <span className={"h2"}>{this.props.text.smallTitle}</span>
                <h2 className={"h1"}>{this.props.text.title}</h2>
                </div>
                <ul className="listing">
                    {this.props.text.bulletpoints.map((element,index)=><li key={index}>{element}</li>)}
                </ul>
            </section>
        )
    }
}

export default OurValuesScreen;
