import {useEffect, useState} from "react";
import BackendService from "../../../Services/backend.service";
let block = false

export const useTopics = () => {
    const [topics, setTopics] = useState([]);
    useEffect(()=>{
        if(topics.length===0 && !block){
            update()
            block = true
        }
    })
    const update = () => {
        BackendService.getAllTopics("force").then(
            ({data})=>{
                setTopics(data)
                console.log("test")
                console.log(data)

            }
        ).catch((error)=>{
        })
    }

    return [topics, update]
}