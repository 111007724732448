import React, { Component } from 'react';
import BigButton from '../../Components/BigButton/BigButton';
import './AboutYouScreen.scss';
import { useState } from 'react';
import {ReactComponent as AboutYouIcon} from './svgs/aboutyoulogo.svg';
import ReactConfetti from "react-confetti";
import {useWindowSize} from "@react-hook/window-size";

/** @module AboutYouScreen */

/**
 * The {@link AboutYouScreen} shows options on how the user behaves.
 * After the user chose every choice the component {@link PerfectMatch} appears
 */
export default class AboutYouScreen extends Component {

    /**
     * sets the initial state
     * @param props
     */
    constructor(props){
        super(props);
        this.state = {
            opts: [false, false, false, false, false],
            alreadyShown: false
        }
    }

    componentDidUpdate(){
        if(!this.state.opts.includes(false) && !this.state.alreadyShown){
            let perfectMatchElement = document.getElementById("perfect-match");
            perfectMatchElement.scrollIntoView({behavior: "smooth", block: "center"});
            this.setState({
                alreadyShown: true //wenn sprache geändert wird, wird nicht nochmal hingescrollt
            })
        }
    }

    setOpt(number){
        let optsyCopy = this.state.opts;
        optsyCopy[number] = true;
        this.setState({
            opts: optsyCopy
        })
    }

    render() {
        return (
            <section className="screen" id="about-you-screen">
                <AboutYouIcon className="aboutyou-icon"/>
                <span className={"h2"}>{this.props.text.smallTitle}</span>
                <h2 className={"h1"}>{this.props.text.title}</h2>
                <div className="vs">
                    <VSToggle setOpt={_=>this.setOpt(0)} name="opt1" text1={this.props.text.vs[0]} text2={this.props.text.vs[1]} />
                    <VSToggle setOpt={_=>this.setOpt(1)} name="opt2" text1={this.props.text.vs[2]} text2={this.props.text.vs[3]} />
                    <VSToggle setOpt={_=>this.setOpt(2)} name="opt3" text1={this.props.text.vs[4]} text2={this.props.text.vs[5]} />
                    <VSToggle setOpt={_=>this.setOpt(3)} name="opt4" text1={this.props.text.vs[6]} text2={this.props.text.vs[7]} />
                    <VSToggle setOpt={_=>this.setOpt(4)} name="opt5" text1={this.props.text.vs[8]} text2={this.props.text.vs[9]} />
                </div>
                {this.state.opts.includes(false)?<></>:<PerfectMatch id="perfect-match" text={this.props.text}/>}
            </section>
        )
    }
}

function VSToggle(props){

    const [active, setActive] = useState({
        opt1: false,
        opt2: false
    });

    const handleChange = (e) => {
        props.setOpt(); // eine Auswahl wurde getroffen
        if(active.opt1 === false && active.opt2 === false)
        {
            if(e.target.value === "1"){
                setActive({
                    opt1: true,
                    opt2: false
                })
            }else{
                setActive({
                    opt1: false,
                    opt2: true
                })
            }
        }else{
            let newState = {
                opt1: !active.opt1,
                opt2: !active.opt2,
            }
            setActive(newState);
        }
    }

    return (
        <div className="vs-toggle">
            <BigButton active={active.opt1} onChange={handleChange} value="1" name={props.name}>{props.text1}</BigButton>
            <p>VS.</p>
            <BigButton active={active.opt2} onChange={handleChange} value="2" name={props.name}>{props.text2}</BigButton>
        </div>
    );
}

function PerfectMatch(props){
    const [width, height] = useWindowSize()
    return (
        <div className="perfect-match" id={props.id}>
                <ReactConfetti
                    className={"confetti"}
                    style={{position: "fixed", zIndex: "1101", top: "-10px"}}
                    recycle={false}
                    tweenDuration={10000}
                    numberOfPieces={500}
                    width={width}
                    height={height}
                />
            <div className="icon">🎉</div>
            <p className="title">{props.text.resultTitle}</p>
            <p className="text">{props.text.resultText.split(".")[0]}.<br />{props.text.resultText.split(".")[1]}.<br />{props.text.resultText.split(".")[2]}.</p>
        </div>
    );
}
