import {Form, FormCheck, FormControl, FormGroup} from "react-bootstrap";
import React, {useContext, useState} from "react";
import * as yup from "yup";
import {Formik} from "formik";
import AdminContext from "../AdminContext/AdminContext";
import BackendService from "../../../Services/backend.service";

export const Topics = (props) => {
    const {
        topics,
        updateTopics,
        topicElements,
        updateTopicElements,
        setAlert
    } = useContext(AdminContext)
    const [initialValues] = useState({
        nameDE: '',
        nameEN: '',
        topicName: '#!NEW',
        topicElementName: '#!CHNG'
    })
    const handleSubmit = (values, form) => {
        switch (values.submitType) {
            case "add":
                if(values.topicName === "#!NEW"){
                    BackendService.postTopic({
                        de: values.nameDE,
                        en: values.nameEN
                    }).finally(()=>{
                        setAlert({msg: "Topic erfolgreich erstellt!", show: true, variant: "info"})
                        updateTopics()
                    }).catch(error=>{
                        setAlert({msg: error.toString(), show: true, variant: "danger"})
                    })
                }else {
                    BackendService.postTopicElement({
                        de: values.nameDE,
                        en: values.nameEN,
                        topicName: values.topicName
                    }).finally(()=>{
                        setAlert({msg: "Topicelement erfolgreich erstellt!", show: true, variant: "info"})
                        updateTopics()
                        updateTopicElements()
                    }).catch(error=>{
                        setAlert({msg: error.toString(), show: true, variant: "danger"})
                    })
                }
                break
            case "change":
                if(values.topicElementName === "#!CHNG")  {
                    BackendService.putTopic({
                            name: values.nameDE,
                            nameEn: values.nameEN
                        }, findTopicByName(values.topicName).id
                    ).finally(() => {
                        setAlert({msg: "Topic erfolgreich geupdated!", show: true, variant: "info"})
                        updateTopics()
                        updateTopicElements()
                    }).catch(error => {
                        setAlert({msg: error.toString(), show: true, variant: "danger"})
                    })
                } else {
                    BackendService.putTopicElement({
                            name: values.nameDE,
                            nameEn: values.nameEN,
                            topicName: values.topicName
                        }, findTopicElementByName(values.topicElementName).id
                    ).finally(() => {
                        setAlert({msg: "Topicelement erfolgreich geupdated!", show: true, variant: "info"})
                        updateTopics()
                        updateTopicElements()
                    }).catch(error => {
                        setAlert({msg: error.toString(), show: true, variant: "danger"})
                    })
                }
                break
            case "delete":
                if(values.topicElementName === "#!CHNG") {
                    findTopicByName(values.topicName).elements.forEach(elmt=>{
                        BackendService.deleteTopicElement(elmt.id).catch(error => {
                            setAlert({msg: error.toString(), show: true, variant: "danger"})
                        })
                    })
                    setTimeout(
                        ()=>BackendService.deleteTopic(findTopicByName(values.topicName).id)
                            .finally(()=>{
                                setAlert({msg: "Topic erfolgreich gelöscht!", show: true, variant: "info"})
                                updateTopics()
                                updateTopicElements()
                            }).catch(error => {
                            setAlert({msg: error.toString(), show: true, variant: "danger"})
                        }),
                        300
                    )
                }else {
                    BackendService.deleteTopicElement(findTopicElementByName(values.topicElementName).id)
                    .finally(()=>{
                        setAlert({msg: "Topicelement erfolgreich gelöscht!", show: true, variant: "info"})
                        updateTopics()
                        updateTopicElements()
                    }).catch(error => {
                    setAlert({msg: error.toString(), show: true, variant: "danger"})
                    })
                }
                break
            default:
                break
        }
        form.resetForm()
    }

    const validationSchema = yup.object().shape(
        {
            nameDE: yup.string().required(),
            nameEN: yup.string().required(),
            topicName: yup.string()
        }
    )

    const findTopicByName = (name) => {
        let result;
        topics.forEach((value)=>{
            if(value.name === name){
                result = value
            }
        })
        return result
    }

    const findTopicElementByName = (name) => {
        let result;
        topicElements.forEach((value)=>{
            if(value.name === name){
                result = value
            }
        })
        return result
    }

    const FormikWrapper = ({InnerForm})=>(
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}>
            {
                (props) => (
                    <InnerForm {...props}/>
                )
            }
        </Formik>
    )

    const FormikFormTopics = (props) => (
        <Form noValidate onSubmit={props.handleSubmit}>
            <FormAuswahl {...props}/>
            {
            props.values.topicName==="#!NEW"?null
            :<><hr/><FormUnterAuswahl {...props}/></>
            }
            <FormName {...props}/>
            <Form.Control disabled={
                !!!(
                    (
                        (
                            props.values.topicElementName==='#!NEW'
                            ||
                            props.values.topicElementName==='#!CHNG'
                        )
                        &&
                        props.values.topicName==='#!NEW'
                    )
                    ||
                    (
                        props.values.topicElementName==='#!NEW'
                        &&
                        props.values.topicName!=='#!NEW'
                    )
                )
            } type="submit" onClick={()=>props.setFieldValue("submitType", "add")} role={"button"} value="hinzufügen"/>
            <Form.Control disabled={
                (
                    props.values.topicName==='#!NEW'
                    ||
                    props.values.topicElementName==='#!NEW'
                )
            } type="submit" onClick={()=>props.setFieldValue("submitType", "change")} role={"button"} value="ändern"/>
            <Form.Control disabled={
                (
                    props.values.topicName==='#!NEW'
                    ||
                    props.values.topicElementName==='#!NEW'
                )
            } type="submit" onClick={()=>props.setFieldValue("submitType", "delete")} role={"button"} style={{backgroundColor: "rgba(255,0,0, 0.5)"}} value="löschen"/>
        </Form>
    )

    const FormUnterAuswahl = ({handleChange, values, setFieldValue, setFieldTouched}) => (
        <Form.Group role={"group"} onChange={handleChange}>
            {
                values.topicName?
                    findTopicByName(values.topicName)?.elements?.map(
                        element=>{
                            return (
                                <div key={element.id}>
                                    <FormCheck
                                        type={"radio"}
                                        label={element.name}
                                        value={element.name}
                                        name={"topicElementName"}
                                        onChange={(event)=>{
                                            const topicElement = findTopicElementByName(event.target.value)
                                            setFieldValue('nameDE', topicElement.name)
                                            setFieldTouched('nameDE', false)
                                            setFieldValue('nameEN', topicElement.nameEn)
                                            setFieldTouched('nameEN', false)
                                            }
                                        }
                                    />
                                </div>

                            )
                        }
                    )
                    :<></>
            }
            <hr />
            <FormCheck
                type={"radio"}
                label={"Neue Unterkategorie"}
                value={"#!NEW"}
                name={"topicElementName"}
                onChange={(event)=>{
                    setFieldValue('nameDE', '')
                    setFieldValue('nameEN', '')
                    setFieldTouched('nameDE', false)
                    setFieldTouched('nameEN', false)
                }
                }
            />
            <FormCheck
                type={"radio"}
                label={"Topic ändern"}
                value={"#!CHNG"}
                name={"topicElementName"}
                defaultChecked={true}
                onChange={(event)=>{
                    setFieldValue('nameDE', findTopicByName(values.topicName).name)
                    setFieldValue('nameEN', findTopicByName(values.topicName).nameEn)
                    setFieldTouched('nameDE', false)
                    setFieldTouched('nameEN', false)
                }
                }
            />
        </Form.Group>
    )

    const FormAuswahl = ({handleChange, setFieldValue, setFieldTouched}) => (
        <Form.Group role={"group"} onChange={handleChange}>
            {
                topics.map(
                    (element, index) =>
                        <div key={index}>
                            <FormCheck
                                type={"radio"}
                                label={element.name}
                                value={element.name}
                                name={"topicName"}
                                onChange={(event) => {
                                    setFieldValue('nameDE', findTopicByName(event.target.value).name)
                                    setFieldValue('nameEN', findTopicByName(event.target.value).nameEn)
                                    setFieldTouched('nameDE', false)
                                    setFieldTouched('nameEN', false)
                                    }
                                }
                            />
                        </div>
                )
            }
            <FormCheck
                type={"radio"}
                label={"Neue Oberkategorie"}
                name={"topicName"}
                value={"#!NEW"}
                onChange={(event)=>{
                    setFieldValue('topicElementName', "#!CHNG")
                    setFieldValue('nameDE', '')
                    setFieldValue('nameEN', '')
                    setFieldTouched('nameDE', false)
                    setFieldTouched('nameEN', false)
                }}
                defaultChecked={true}
            />
        </Form.Group>
    )

    const FormName = ({handleChange, handleBlur, values, touched, errors})=>(
        <>
        <FormGroup>
            <FormControl onBlur={handleBlur} onChange={handleChange} value={values.nameDE} name={"nameDE"} placeholder={"topic deutsch"} isInvalid={!!errors.nameDE && touched.nameDE}/>
            {!!errors.nameDE && touched.nameDE?<span style={{color: "red"}}>{errors.nameDE}</span>:null}
        </FormGroup>
        <FormGroup>
            <FormControl onBlur={handleBlur} onChange={handleChange} value={values.nameEN} name={"nameEN"} placeholder={"topic englisch"} isInvalid={!!errors.nameEN && touched.nameEN}/>
            {!!errors.nameEN && touched.nameEN?<span style={{color: "red"}}>{errors.nameEN}</span>:null}

        </FormGroup>
        </>
    )

    return (
        <section className={"Topics"}>
            <h2>Topics</h2>
            <FormikWrapper InnerForm={FormikFormTopics} />
        </section>
    )
}