import {useMembers} from "../AdminHooks/useMembers";
import AdminContext from "./AdminContext";
import {useTopicElements} from "../AdminHooks/useTopicElements";
import {useTestimonials} from "../AdminHooks/useTestimonials";
import {useTopics} from "../AdminHooks/useTopics";
import {useState} from "react";

export const AdminState = ({children}) => {
    const [members, updateMembers] = useMembers()
    const [topics, updateTopics] = useTopics()
    const [topicElements, updateTopicElements] = useTopicElements()
    const [testimonials, updateTestimonials] = useTestimonials()
    const [alert, setAlert] = useState({show: false, msg: '', variant: 'info'})
    return (
        <AdminContext.Provider value={{
            members: members,
            updateMembers: updateMembers,
            topics: topics,
            updateTopics: updateTopics,
            topicElements: topicElements,
            updateTopicElements: updateTopicElements,
            testimonials: testimonials,
            updateTestimonials: updateTestimonials,
            alert: alert,
            setAlert: setAlert
        }}>
            {children}
        </AdminContext.Provider>
    )
}