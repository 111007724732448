import './EncodeLogin.scss'
import {Form, FormControl, FormLabel} from "react-bootstrap";
import {useState} from "react";
import BackendService from '../../Services/backend.service'
export default function EncodeLogin(props){
    const [pw, setPw] = useState();
    const onSubmit = (event) => {
        event.preventDefault()
        console.log(pw)
        BackendService.postCheckEncode(pw).then(response=>{
            window.history.go()
        })
    }
    return (
        <div className="EncodeLogin">
            <Form onSubmit={onSubmit}>
                <FormLabel>Access Denied - Please enter the correct password</FormLabel>
                <FormControl type={"password"} placeholder={"password"} value={pw} onChange={ev=>setPw(ev.target.value)}/>
                <FormControl type={"submit"} value={"let me in 🚪"}/>
            </Form>
        </div>
    )
}
