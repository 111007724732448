import {useEffect, useState} from "react";
import BackendService from "../../../Services/backend.service";
let block = false

export const useTopicElements = () => {
    const [topicElements, setTopicElements] = useState([]);
    useEffect(()=>{
        if(topicElements.length===0 && !block){
            update()
            block = true
        }
    })
    const update = () => {
        BackendService.getAllTopicElements(null, "force").then(
            ({data})=>{
                setTopicElements(data)
                console.log("test")
            }
        ).catch((error)=>{
        })
    }

    return [topicElements, update]
}