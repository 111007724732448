import React, {Component, useContext, useEffect} from 'react';
import { useState } from 'react';
import NormalButton from '../../Components/NormalButton/NormalButton';
import './SimilatirysScreen.scss';
import TextContext from "../../Text/TextContext";
import BackendService from "../../Services/backend.service";

export class SimilatirysScreen extends Component {

    width = 14;
    height = 7;

    constructor(props){
        super(props);
        this.state = {
            picturespots: null,
            randomspots: null,
            filter: "",
            members: []
        }
        this.setFilter =  this.setFilter.bind(this);
    }

    componentDidMount(){

        BackendService.getAllEncodeMembers().then(response=>{
            if(response?.data) {
                this.setState({
                    members: response.data
                }, () => {
                    this.setState({
                        picturespots: this.generateEmptyDivs(),
                        randomspots: this.generateRandomSpots(),
                    }, () => {
                        this.insertImagesIntoSpots();
                    })
                })
            }
        })
    }

    setFilter(filter){
        this.setState(
            {
                filter: filter,
            }, ()=>this.reorder()
        )
    }

    insertImagesIntoSpots(){
        let index = 0;
        this.state.randomspots.forEach(spot=>{
                let element = document.getElementById(`spot-${spot}`);
                let elementText = document.getElementById(`hover-text-${spot}`);
                if (element) {
                    let xOffset = Math.floor((Math.random() * (30 + 30)) - 30);
                    let yOffset = Math.floor((Math.random() * (30 + 30)) - 30);

                    BackendService.getEncodeMemberImage(this.state.members[index].id).then(
                        response => {
                            try {
                                let url = URL.createObjectURL(response.data);
                                element.setAttribute("style", `
                        left: ${xOffset}px; 
                        top: ${yOffset}px;
                        background-image: url(${url})`
                                );
                                elementText.setAttribute("style", `
                        left: ${xOffset}px;
                        top:  ${70 + yOffset + 18}px`
                                );
                            } catch (e) {
                                console.warn("Could not load Image " + e.toString())
                            }
                        }
                    )
                }
            index++;
        })
    }

    generateRandomSpots(){
        //generiert zufällig stellen andem ein bild kommen soll

        let picture_amount = this.state.members.length;
        let random_spot = [];
        for(let i = 0; i < picture_amount; i++)
        {
            if(this.state.members[i].istEhemalig) continue; //Ehemalige Members nicht einfügen!
            let spot = 0;
            do{
                spot = Math.floor((Math.random()*this.width*this.height));
            } while(
                //evtl noch abstand mit einrechnen und mitte vermeiden
                random_spot.includes(spot)
                //-------Seiten/Oben/Unten vermeiden------
                || random_spot.includes(spot+1)
                || random_spot.includes(spot-1)
                || random_spot.includes(spot+this.width)
                || random_spot.includes(spot-this.width)
                //-------Quere Nachbarn vermeiden------
                || random_spot.includes(spot+this.width+1)
                || random_spot.includes(spot-this.width+1)
                || random_spot.includes(spot+this.width-1)
                || random_spot.includes(spot-this.width-1)
                //-------Mitte vermeiden------
                || spot === Math.floor(this.width*this.height/2)
                || spot === Math.floor(this.width*this.height/2+this.width)
                || spot === Math.floor(this.width*this.height/2-this.width)
                || spot === Math.floor(this.width*this.height/2+this.width*2)
                || spot === Math.floor(this.width*this.height/2-this.width*2)
                || spot === Math.floor(this.width*this.height/2-1)
                || spot === Math.floor(this.width*this.height/2+this.width-1)
                || spot === Math.floor(this.width*this.height/2-this.width-1)
                || spot === Math.floor(this.width*this.height/2+this.width*2-1)
                || spot === Math.floor(this.width*this.height/2-this.width*2-1)
            );
            random_spot.push(spot);
        }
        return random_spot;
    }

    generateEmptyDivs(){
        let rows = [];
        for(let i = 0; i < this.width*this.height; i++)
        {
            rows.push(<div className={"member-image"} id={`spot-${i}`}/>)
        }
        return rows;
    }

    reorder(){
        this.setState({
            picturespots: this.generateEmptyDivs(),
            randomspots: this.generateRandomSpots()
        }, _=>{
            this.insertImagesIntoSpots();
        })
    }

    clickImageUpload(){
        let fileUploadElement = document.getElementById("image-upload");
        fileUploadElement.click();
    }

    uploadFile(e){
        let image = e.target.files[0];
        if(image)
        e.target.parentElement.setAttribute('style', "background-image: url("+URL.createObjectURL(image) + "); background-size: cover");
        e.target.parentElement.children[1].setAttribute('style', 'visibility: hidden;')
    }

    render() {
        return (
            <section className="screen" id="similatiry-screen">
                <span className={"h2"}>{this.props.text.smallTitle}</span>
                <h2 className={"h1"}>{this.props.text.title}</h2>
                <Topics setFilter={filter=>this.setFilter(filter)} />
                <div className="bilder-container">
                    {
                        this.state.picturespots?
                        this.state.picturespots
                        .map(
                            (element, index)=>{
                                if(element){
                                    let spot = parseInt(element.props.id.substr(5));
                                    if(this.state.randomspots.includes(spot))
                                    {
                                        let inFilter = this.state.members[this.state.randomspots.indexOf(spot)].likings?.includes(this.state.filter);
                                        if((inFilter || this.state.filter==="")
                                            && !this.state.members[this.state.randomspots.indexOf(spot)].istEhemalig)
                                        {
                                        return (
                                                    <div className="spot" key={index}>{element}
                                                        <div className="hover-text" id={"hover-text-" + element.props.id.substr(5)}>
                                                            <p>
                                                            {this.state.members[this.state.randomspots.indexOf(spot)].vorname}
                                                            {" "}
                                                            {this.state.members[this.state.randomspots.indexOf(spot)].nachname}

                                                            {   this.state.members[this.state.randomspots.indexOf(spot)].bereich?
                                                                <small>{this.state.members[this.state.randomspots.indexOf(spot)].bereich}</small>
                                                                :<></>
                                                            }
                                                            </p>
                                                        </div>
                                                    </div>
                                        )}else{
                                            return <div key={index}></div>
                                        }
                                    }else{
                                        return <div key={index}></div>
                                    }
                                }
                                return element;
                            }
                        )
                            :<></>
                    }
                    <div onClick={_=>this.clickImageUpload()} className="add-image">
                        <input onChange={e=>this.uploadFile(e)} id="image-upload" type="file" style={{display: "none"}}/>
                        <div className="text">
                            <strong>{this.props.text.addImageTitle}<br /></strong>
                            <small>{this.props.text.addImageText}</small>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

function Topics(props){
    const [buttons, setButtons] = useState(-1);
    const [topics, setTopics] = useState(null);

    const setButtonsToggle = (i)=>{
        if(i!==buttons){
            setButtons(i);
        }else if (i>=0){
            setButtons(-1)
        }
    }

    useEffect(()=>{
        if(!topics){
            BackendService.getAllTopics().then(response=>{
                if(response?.data)
                    setTopics(response.data)
            })
        }
    })
    const { text } = useContext(TextContext);

    if(topics)
    return (
    <>
        <nav className="topics">
            {
                topics.map((element, index)=><Topic
                    auswahl={buttons} setButtons={i=>setButtonsToggle(i)}
                    key={index}
                    index={index}
                >
                    {text.language==="de"?element.name:element.nameEn}
                </Topic>)
            }
            <NormalButton onClick={_=> {
                props.setFilter("");
                setButtons(-1);
            }} >🔄  Reset</NormalButton>
        {topics[buttons]?
            <>
                <hr className={"linie"} />
                <TopicElements setButtons={i=>setButtons(i)} setFilter={props.setFilter} elements={topics[buttons].elements}/></>:<></>}
        </nav>

    </>
    );
    return (<></>)
}

function Topic(props){
    const index = props.index;
    return (
        <NormalButton onClick={_=>{props.setButtons(index)}} >{props.children}</NormalButton>
    )
}

function TopicElements(props){
    const setFilter = (text) => props.setFilter(text);
    const { text } = useContext(TextContext);

    return (
        <div className="topics-elements">
            {
                props.elements.map((element, index) => <TopicElement
                    setButtons={i=>props.setButtons(i)}
                    setFilter={setFilter}
                    name={text.language==="de"?element.name:element.nameEn}
                    filter={element.name}
                    key={index} />)
            }
        </div>
    );
}
function TopicElement(props){
    return (
        <><NormalButton onClick={_=> {
            props.setFilter(props.filter);
            props.setButtons(-1);
        }}>{props.name}</NormalButton></>
    )
}
export default SimilatirysScreen;
