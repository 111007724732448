import React, {useContext, useEffect, useRef, useState} from 'react';
import './TestimonialScreen.scss';
import {ReactComponent as BallHell} from './svgs/ball-hell.svg';
import {ReactComponent as BallDunkel} from './svgs/ball-dunkel.svg';
import BackendService from '../../Services/backend.service';
import {useGesture} from "@use-gesture/react";
import {isBigView, swipeAction} from './TestimonialHelper';
import TextContext from "../../Text/TextContext";

export function TestimonialScreen(props){
    const ref = useRef();
    return (
        <section className="screen" id="testimonial-screen">
            <h2 className={"h3 title"}>{props.text.title}</h2>
            <div className="testimonial-slider" ref={ref}>

                <div className="balle-container">
                    <BallDunkel className="small-ball-dark"/>
                    <BallDunkel className="mid-ball-dark"/>
                    <BallHell className="small-ball-light"/>
                    <BallHell className="mid-ball-light"/>
                    <BallHell className="big-ball-light"/>
                </div>
                <SliderElements/>
            </div>
        </section>
    )
}

function SliderElements(props) {

    const [elements, setElements] = useState([]);
    const [transition, setTransition] = useState(true);
    const [pointer, setPointer] = useState(3);
    //const [wheelScrollDirection, setWheelScrollDirection] = useState(0);
    const slider = useRef(null);
    const context = useContext(TextContext);
    const [currentLanguage, setCurrentLanguage] = useState("de")
    const [language, setLanguage] = useState("de");
    useEffect(()=>{
        let language = context.text.language;
        setLanguage(language);
    }, [context, language])

    useEffect(()=>{
        const changed = currentLanguage!==language;
        setCurrentLanguage(language);
        if(elements?.length<1 || changed) {
            setElements(["Could not load any data", "..."])
            let elementsCopy = [];
            BackendService.getAllEncodeTestimonials(language).then((response) => {
                let promArray = response?.data?.map((element, index) => {
                    return BackendService.getEncodeMemberImage(element.by.id).then(response=> {
                        elementsCopy.push(
                            <SliderElement key={index}>
                                <div className={"slider-element-header"}>
                                    <div className={"image image-id-" + element.by.id}
                                         style={response?.data?{backgroundImage: "url(" + URL.createObjectURL(response.data) + ")"}:{}}>
                                    </div>
                                    <p className={"by"}>
                                        {element.by.vorname + " " + element.by.nachname}
                                    </p>
                                </div>
                                <div className={"slider-element-body"}>
                                    <q className={"text"}>
                                        {element.text}
                                    </q>
                                </div>
                            </SliderElement>
                        );
                    }).catch(_=>{
                        elementsCopy.push(
                            <SliderElement key={index}>
                                <div className={"slider-element-header"}>
                                    <div className={"image image-id-" + element.by.id}>
                                    </div>
                                    <div className={"by"}>
                                        {element.by.vorname + " " + element.by.nachname}
                                    </div>
                                </div>
                                <div className={"slider-element-body"}>
                                    <div className={"text"}>
                                        {element.text}
                                    </div>
                                </div>
                            </SliderElement>
                        );
                    });
                })
                try{
                    if(promArray)
                    Promise.all(promArray).then(
                        () => {
                            elementsCopy.sort((a, b) => {
                                return a.key - b.key
                            })
                            if (elementsCopy.length < 3) {
                                setElements(null);
                                return;
                            }

                            elementsCopy.push(
                                elementsCopy[0],
                                elementsCopy[1],
                                elementsCopy[2],
                            );
                            elementsCopy.unshift(
                                elementsCopy[elementsCopy.length - 3 - 3],
                                elementsCopy[elementsCopy.length - 2 - 3],
                                elementsCopy[elementsCopy.length - 1 - 3],
                            );

                            setElements(elementsCopy);
                            setPointer(2);
                        }
                    )
                }catch(e){
                    console.log(e)
                }
            })
        }
    }, [elements, language, currentLanguage])

    const gestureBind = useGesture({
        onDrag: (
            swipeAction( (direction)=> {
                move(direction);
            })
        ),
        /* Todo
        onWheelStart: (state)=>{
            if (state.axis === "x") {
                setWheelScrollDirection(state.direction[0])
            }
            //state.event.nativeEvent.currentTarget = slider.current
            console.log(state.event.nativeEvent)

        },
        onWheelEnd: (state)=>{
            setWheelScrollDirection(0);
        }
        */
    },{
        //config
    })

    const move = (direction)=> {
        if(direction===0){
            return;
        }
        setTransition(true);
        setPointer(prevState =>
            (prevState+direction>0)?
                (prevState+direction<elements.length-3)?
                        prevState+direction //In richtung gehen
                    :elements.length-3
                :0)
    }

    const handleTransitionEnd = ()=>{
        if(pointer===0){
            setTransition(false)
            setPointer(elements.length-3-3)
        }
        else if(pointer===elements.length-3){
            setTransition(false)
            setPointer(3)
        }
    }

    if(elements?.length>2)
        return (
            <>
                <div className={"slider-elements"} {...gestureBind()} ref={slider}>
                    {elements.map((element, index) => {

                            const bigView = slider?.current?isBigView(slider.current):false;

                            return <div
                                key={index}
                                style={{
                                    opacity: (
                                        index === pointer ||
                                        index === pointer + 1 ||
                                        index === pointer + 2
                                    ) ? 1 :  bigView? 0 : 1,
                                    transform:
                                        `translateX(-${(100) * (pointer)}%) ${
                                            index === pointer + 1
                                            &&
                                            slider?.current
                                            &&
                                            bigView ?
                                                "scale(1.2)" : "scale(1.0)"}`,
                                    transition: transition ? "all 0.45s ease-in-out" : "none"
                                }}
                                onTransitionEnd={_ => handleTransitionEnd()}
                                className={"slider-element-container"}
                            >
                                {element}
                            </div>
                        }
                    )}
                </div>
                <button title={"slide right"} aria-label={"slide right"} onClick={event=>move(+1)} className={"arrow-right"}></button>
                <button title={"slide left"} aria-label={"slide left"} onClick={event=>move(-1)} className={"arrow-left"}></button>
            </>
        )
    return <div>Not enough testimonials yet</div>
}

function SliderElement(props){
    return (
        <div className={"slider-element"}>
            {props.children}
        </div>
    )
}

export default TestimonialScreen;
