export function isBigView(element){
    return parseInt(window.getComputedStyle(element)
        .getPropertyValue(
            "--anzahl-sichtbarer-elemente"
        )) === 3;
}

export function swipeAction(clbk = (direction)=>{}){
    return ({swipe: [swipeX]})=>{
        if(swipeX!==0){
            clbk(swipeX*-1);
        }
    }
}