import BackendService from "../../../Services/backend.service"
import {Form} from "react-bootstrap"
import React, {useContext} from "react"
import AdminContext from "../AdminContext/AdminContext"

export function ChangePassword(props){
    const {setAlert} = useContext(AdminContext)
    const handleSubmit = (e) => {
        e.preventDefault()
        let oldPW = e.target[0].value
        let newPW = e.target[1].value
        let newPWCheck = e.target[2].value
        if(oldPW===""){
            setAlert({
                msg: "Um das Passwort zu ändern, bitte noch einmal aus- und einloggen!",
                show: true,
                variant: "danger"
            })
            e.target.reset()
            return
        }
        if( oldPW === props.pw){
            if( newPW === newPWCheck && newPW !== ""){
                BackendService.postChangePassword(newPW, oldPW).then(_=>{
                    setAlert({
                        msg: "Passwort geändert",
                        show: true,
                        variant: "info"
                    })
                })
            }else {
                setAlert({
                    msg: "Passwörter stimmen nicht überein",
                    show: true,
                    variant: "warning"
                })
            }
        }else {
            setAlert({
                msg: "Altes Password stimmt nicht",
                show: true,
                variant: "danger"
            })
        }
        e.target.reset()
    }
    return (
        <div className="changePassword">
            <h2>Change Password</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Control type={"password"} placeholder="Old Password"/>
                </Form.Group>
                <Form.Group>
                    <Form.Control type={"password"} placeholder="New Password"/>
                </Form.Group>
                <Form.Group>
                    <Form.Control  type={"password"} placeholder="New Password Confirm"/>
                </Form.Group>
                <Form.Control type="submit" value="ändern"/>
            </Form>
        </div>
    )
}
