import React, { Component } from 'react';
import './CustomerScreen.scss';
import DatevLogo from './imgs/datev.png';
import TeamBankLogo from './imgs/teambank.png';
import AgenturFA from  './imgs/agenturfuerarbeit.png'
export class CustomerScreen extends Component {
    render() {
        return (
            <section className="screen" id="customer-screen">
                <h2 className={"h1"}>{this.props.text.title} 💪</h2>
                <div className="cards">
                    <div className="cards-container">
                    <CardBox id="customer-card-1" height={"90"} logo={DatevLogo} alt={"Datev Logo"}>
                    </CardBox>
                    <CardBox id="customer-card-2" logo={TeamBankLogo} alt={"TeamBank Logo"}>
                    </CardBox>
                    <CardBox id="customer-card-3"  logo={AgenturFA} alt={"Agentur für Arbeit Logo"}></CardBox>
                    </div>
                </div>
            </section>
        )
    }
}

function CardBox(props){
    return (
        <div className="customer-card" id={props.id}>
            <div className="logo">
                <img height={props.height?props.height:"66"} alt={props.alt} src={props.logo}/>
            </div>
        </div>
    )
}

export default CustomerScreen;
