import React, {useContext} from 'react';
import {Alert, Button} from "react-bootstrap";
import './AdminPanel.scss'
import {Link} from "react-router-dom";
import {Members} from "./Forms/Members";
import {Topics} from "./Forms/Topics";
import {Testimonials} from "./Forms/Testimonials";
import {ChangePassword} from "./Forms/ChangePassword";
import AdminContext from "./AdminContext/AdminContext";

export default function AdminPanel(props) {
    const {alert, setAlert} = useContext(AdminContext)
    return (
        <div className="admin-panel">
            {
                alert.show?
                <Alert variant={alert.variant}
                       style={{position: "fixed", left: 0, top: 0, width: "100%", height: "max-content", minHeight: "150px", display: "grid", placeItems: "center"}}
                       className={"slide-in"}
                       onClose={() => setAlert(prevState => ({...prevState, show: false}))} dismissible>
                    <span>{alert.msg}</span>
                </Alert>:null
            }
            <Button onClick={()=>{
                sessionStorage.removeItem('user')
                window.history.go();
            }} style={{margin: "10px"}}>Logout</Button>
            <Link to="/">---Zurück zur Homepage---</Link>
            <div className={"edit-fenster"}>
                <Members/>
                <Topics/>
                <Testimonials/>
                <ChangePassword pw={props.pw}/>
            </div>
            <Link to="/">---Zurück zur Homepage---</Link>
        </div>
    );
}